import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
import Rating from './Rating';
import axios from 'axios';
import { useContext } from 'react';
import { Store } from '../Store';
import { toast } from 'react-toastify';
import { getError } from '../utils';

function Product(props) {
  const { product } = props;

  const { state, dispatch: ctxDispatch } = useContext(Store);
  const {
    cart: { cartItems },
  } = state;

  const sellHandler = async () => {
    if (window.confirm('Sell This Product To Us?')) {
      try {
        const { data } = await axios.post('/api/offers', {
          product: {
            ...product,
          },
        });
        toast.success('product created successfully');
        window.location.href = `/offer/${data.offer._id}`;
      } catch (err) {
        toast.error(getError('Uh oh'));
      }
    }
  };

  const addToCartHandler = async (item) => {
    const existItem = cartItems.find((x) => x._id === product._id);
    const quantity = existItem ? existItem.quantity + 1 : 1;
    const { data } = await axios.get(`/api/products/${item._id}`);
    if (data.countInStock < quantity) {
      window.alert('Sorry. Product is out of stock');
      return;
    }
    ctxDispatch({
      type: 'CART_ADD_ITEM',
      payload: { ...item, quantity },
    });
  };

  return (
    <Card style={{ maxWidth: '90vw', margin: '0 auto', height: '100%', overflow: 'hidden' }}>
      <Link to={`/product/${product.slug}`}>
        <img src={product.image} className="card-img-top" alt={product.name} style={{ width: '100%', height: 'auto' }} />
      </Link>
      <Card.Body style={{ padding: '10px', height: '100%', display: 'flex', flexDirection: 'column' }}>
        <Link to={`/product/${product.slug}`}>
          <Card.Title>{product.name}</Card.Title>
        </Link>
        <Rating rating={product.rating} numReviews={product.numReviews} />
        <Link to={`/product/${product.slug}`}>
          <Card.Text style={{ color: 'red', cursor: 'pointer' }}>
            We sell for: £{product.sellPrice}
          </Card.Text>
        </Link>
        <Card.Text
          style={{ color: 'green', marginTop: 'auto' }}
          onClick={() => sellHandler(product)}
        >
          We buy for: £{product.buyPrice}
        </Card.Text>
        <Button
          style={{
            width: '100%',
            marginTop: '10px',
            backgroundColor: 'green',
          }}
          onClick={() => sellHandler(product)}
        >
          Sell to us
        </Button>
        {product.countInStock === 0 ? (
          <Button variant="light" disabled style={{ width: '100%', marginTop: '10px' }}>
            Out of stock
          </Button>
        ) : (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              marginTop: 'auto',
            }}
          >
            <Button
              style={{ width: '100%', marginTop: '10px' }}
              onClick={() => addToCartHandler(product)}
            >
              Add to cart
            </Button>
          </div>
        )}
      </Card.Body>
    </Card>
  );
}
export default Product;
