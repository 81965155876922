import React, { useContext, useEffect, useReducer, useState } from 'react';
import Chart from 'react-google-charts';
import axios from 'axios';
import { Store } from '../Store';
import { getError } from '../utils';
import LoadingBox from '../components/LoadingBox';
import MessageBox from '../components/MessageBox';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';

const reducer = (state, action) => {
  switch (action.type) {
    case 'FETCH_REQUEST':
      return { ...state, loading: true };
    case 'FETCH_SUCCESS':
      return {
        ...state,
        summary: action.payload,
        loading: false,
      };
    case 'FETCH_FAIL':
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};
export default function DashboardScreen() {
  const [{ loading, summary, error }, dispatch] = useReducer(reducer, {
    loading: true,
    error: '',
  });
  const { state } = useContext(Store);
  const { userInfo } = state;
  const [address, setAddress] = useState({});

  const fetchAddress = async () => {
    const { data } = await axios.get('/api/address/latest');
    setAddress(data);
    setAddress(data || {});
  };

  useEffect(() => {
    fetchAddress();
    const fetchData = async () => {
      try {
        const { data } = await axios.get('/api/orders/summary', {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        });
        dispatch({ type: 'FETCH_SUCCESS', payload: data });
      } catch (err) {
        dispatch({
          type: 'FETCH_FAIL',
          payload: getError(err),
        });
      }
    };
    fetchData();
  }, [userInfo]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (address._id) {
        // If an address already exists, update it
        await axios.put(`/api/address/${address._id}`, address);
      } else {
        // If no address exists, create a new one
        await axios.post('/api/address', address);
      }
      alert('Address saved successfully!');
    } catch (err) {
      alert('Error saving address: ' + err.message);
    }
  };

  return (
    <div>
      <h1>Dashboard</h1>
      {loading ? (
        <LoadingBox />
      ) : error ? (
        <MessageBox variant="danger">{error}</MessageBox>
      ) : (
        <>
          <Row>
            <Col md={4}>
              <Card>
                <Card.Body>
                  <Card.Title>
                    {summary.users && summary.users[0]
                      ? summary.users[0].numUsers
                      : 0}
                  </Card.Title>
                  <Card.Text> Users</Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4}>
              <Card>
                <Card.Body>
                  <Card.Title>
                    {summary.orders && summary.users[0]
                      ? summary.orders[0].numOrders
                      : 0}
                  </Card.Title>
                  <Card.Text> Orders</Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4}>
              <Card>
                <Card.Body>
                  <Card.Title>
                    £
                    {summary.orders && summary.users[0]
                      ? summary.orders[0].totalSales.toFixed(2)
                      : 0}
                  </Card.Title>
                  <Card.Text> Orders</Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <div className="my-3">
            <h2>Sales</h2>
            {summary.dailyOrders.length === 0 ? (
              <MessageBox>No Sale</MessageBox>
            ) : (
              <Chart
                width="100%"
                height="400px"
                chartType="AreaChart"
                loader={<div>Loading Chart...</div>}
                data={[
                  ['Date', 'Sales'],
                  ...summary.dailyOrders.map((x) => [x._id, x.sales]),
                ]}
              ></Chart>
            )}
          </div>
          <div className="my-3">
            <h2>Categories</h2>
            {summary.productCategories.length === 0 ? (
              <MessageBox>No Category</MessageBox>
            ) : (
              <Chart
                width="100%"
                height="400px"
                chartType="PieChart"
                loader={<div>Loading Chart...</div>}
                data={[
                  ['Category', 'Products'],
                  ...summary.productCategories.map((x) => [x._id, x.count]),
                ]}
              ></Chart>
            )}
          </div>
        </>
      )}
      <h2>Company Address to send parcels</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="firstLine">First Line:</label>
          <input
            type="text"
            className="form-control"
            id="firstLine"
            value={address.firstLine || ''}
            onChange={(e) =>
              setAddress((prev) => ({ ...prev, firstLine: e.target.value }))
            }
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="secondLine">Second Line:</label>
          <input
            type="text"
            className="form-control"
            id="secondLine"
            value={address.secondLine || ''}
            onChange={(e) =>
              setAddress((prev) => ({ ...prev, secondLine: e.target.value }))
            }
          />
        </div>
        <div className="form-group">
          <label htmlFor="city">City:</label>
          <input
            type="text"
            className="form-control"
            id="city"
            value={address.city || ''}
            onChange={(e) =>
              setAddress((prev) => ({ ...prev, city: e.target.value }))
            }
          />
        </div>
        <div className="form-group">
          <label htmlFor="postCode">Post Code:</label>
          <input
            type="text"
            className="form-control"
            id="postCode"
            value={address.postCode || ''}
            onChange={(e) =>
              setAddress((prev) => ({ ...prev, postCode: e.target.value }))
            }
            required
          />
        </div>
        <button type="submit" className="btn btn-primary">
          Save Address
        </button>
      </form>
    </div>
  );
}
