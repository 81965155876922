import React from 'react';

const AboutScreen = () => {
  return (
    <div className="container about-us-page">
    <section className="section">
      <div className="row">
        <div className="col-lg-4 col-md-6 order-md-2 mx-auto text-center">
          <img
            src="/images/phones.png"
            alt="Large Image"
            className="img-fluid"
          />
        </div>
        <div className="col-lg-8 col-md-6 mx-auto text-left order-md-1">
          <h2 className="section-heading">Who Are We?</h2>
          <p className="text-muted">
            Mobile & Console Trader is your premier destination for buying and selling mobile devices and gaming consoles. Our platform allows you to sell your new or used devices hassle-free, and shop for quality products at affordable prices. We guarantee quality at a low cost, and if your item is rejected, we'll send it back to you at no extra cost.
          </p>
        </div>
      </div>
    </section>

      <section className="section bg-light">
        <div className="row">
          <div className="col-lg-8 col-md-10 mx-auto text-center">
            <h2 className="section-heading">Our Location</h2>
            <p className="text-muted">
              Proudly located in the vibrant city of Birmingham, UK, Mobile & Console Trader operates locally while extending our services internationally. We bring our commitment to quality and affordability to customers around the world.
            </p>
          </div>
        </div>
      </section>

      <section className="section">
        <div className="row">
          <div className="col-lg-8 col-md-10 mx-auto text-center">
            <h2 className="section-heading">Contact Us</h2>
            <div className="contact-card">
              <h3>Owner: Allan</h3>
              <p>Phone: 07429008002</p>
              <p>Email: <a href="mailto:allan@mobileandconsoletrader.co.uk">allan@mobileandconsoletrader.co.uk</a></p>
            </div>
          </div>
        </div>
      </section>

    </div>
  );
};

export default AboutScreen;