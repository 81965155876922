import { useEffect, useReducer } from 'react';
import axios from 'axios';
import { Helmet } from 'react-helmet-async';
import LoadingBox from '../components/LoadingBox';
import MessageBox from '../components/MessageBox';
import Product from '../components/Product';

const reducer = (state, action) => {
  switch (action.type) {
    case 'FETCH_REQUEST':
      return { ...state, loading: true };
    case 'FETCH_SUCCESS':
      return { ...state, products: action.payload, loading: false };
    case 'FETCH_FAIL':
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

function HomeScreen() {
  const [{ loading, error, products }, dispatch] = useReducer(reducer, {
    products: [],
    loading: true,
    error: '',
  });

  useEffect(() => {
    const fetchData = async () => {
      dispatch({ type: 'FETCH_REQUEST' });
      try {
        const result = await axios.get('/api/products');
        dispatch({ type: 'FETCH_SUCCESS', payload: result.data });
      } catch (err) {
        dispatch({ type: 'FETCH_FAIL', payload: err.message });
      }
    };
    fetchData();
  }, []);

  const groupByCategory = (products) => {
    return products.reduce((categories, product) => {
      const category = product.category;
      categories[category] = categories[category] || [];
      categories[category].push(product);
      return categories;
    }, {});
  };

  const productsByCategory = groupByCategory(products);

  return (
    <>
      <Helmet>
        <title>Mobile & Console Trader</title>
      </Helmet>
      <div className="container">
        {loading ? (
          <LoadingBox />
        ) : error ? (
          <MessageBox variant="danger">{error}</MessageBox>
        ) : (
          Object.entries(productsByCategory).map(([category, products]) => (
            <div key={category}>
              <h2>{category}</h2>
              <div className="row">
                {products.map((product) => (
                  <div
                    key={product.slug}
                    className="col-md-6 col-lg-4 mb-3 col-sm-6"
                  >
                    <Product product={product} />
                  </div>
                ))}
              </div>
            </div>
          ))
        )}
      </div>
    </>
  );
}

export default HomeScreen;
