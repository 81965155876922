import React, { useContext, useEffect, useReducer, useState } from 'react';
import axios from 'axios';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { toast } from 'react-toastify';
import { Store } from '../Store';
import LoadingBox from '../components/LoadingBox';
import MessageBox from '../components/MessageBox';
import { getError } from '../utils';

const reducer = (state, action) => {
  switch (action.type) {
    case 'FETCH_REQUEST':
      return { ...state, loading: true };
    case 'FETCH_SUCCESS':
      return {
        ...state,
        offers: action.payload.offers,
        page: action.payload.page,
        pages: action.payload.pages,
        loading: false,
      };
    case 'FETCH_FAIL':
      return { ...state, loading: false, error: action.payload };
    case 'CREATE_REQUEST':
      return { ...state, loadingCreate: true };
    case 'CREATE_SUCCESS':
      return {
        ...state,
        loadingCreate: false,
      };
    case 'CREATE_FAIL':
      return { ...state, loadingCreate: false };

    case 'DELETE_REQUEST':
      return { ...state, loadingDelete: true, successDelete: false };
    case 'DELETE_SUCCESS':
      return {
        ...state,
        loadingDelete: false,
        successDelete: true,
      };
    case 'DELETE_FAIL':
      return { ...state, loadingDelete: false, successDelete: false };

    case 'DELETE_RESET':
      return { ...state, loadingDelete: false, successDelete: false };
    default:
      return state;
  }
};

export default function OfferListScreen() {
  const [
    {
      loading,
      error,
      offers,
      pages,
      loadingCreate,
      loadingDelete,
      successDelete,
    },
    dispatch,
  ] = useReducer(reducer, {
    loading: true,
    error: '',
  });

  const navigate = useNavigate();
  const { search } = useLocation();
  const sp = new URLSearchParams(search);
  const page = sp.get('page') || 1;

  const { state } = useContext(Store);
  const { userInfo } = state;
  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await axios.get(`/api/offers/admin?page=${page} `, {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        });

        dispatch({ type: 'FETCH_SUCCESS', payload: data });
      } catch (err) {}
    };

    if (successDelete) {
      dispatch({ type: 'DELETE_RESET' });
    } else {
      fetchData();
    }
  }, [page, userInfo, successDelete]);

  const [confirmedOffers, setConfirmedOffers] = useState([]);
useEffect(() => {
  const fetchConfirmedOffers = async () => {
    try {
      const { data } = await axios.get('/api/confirmedoffers', {
        headers: { Authorization: `Bearer ${userInfo.token}` },
      });
      setConfirmedOffers(data);
    } catch (err) {
      dispatch({ type: 'FETCH_FAIL', payload: getError(err) });
    }
  };
  fetchConfirmedOffers();
}, [userInfo, dispatch]);

  const deleteHandler = async (offer) => {
    if (window.confirm('Are you sure you want to delete?')) {
      try {
        await axios.delete(`/api/offers/${offer._id}`, {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        });
        toast.success('offer deleted successfully');
        dispatch({ type: 'DELETE_SUCCESS' });
      } catch (err) {
        toast.error(getError(error));
        dispatch({
          type: 'DELETE_FAIL',
        });
      }
    }
  };

  const moveItemHandler = async (offer) => {
    if (
      window.confirm(
        'Are you sure you want to Authorise? This will add the item to the app'
      )
    ) {
      try {
        // Step 1: Find the item in the offers table
        const response = await axios.get(`/api/offers/${offer._id}`, {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        });
        const offerItem = response.data;
        console.log('offerItem:', offerItem);

        // Step 2: Save a copy of the item's attributes
        const confirmedItem = {};
        Object.assign(confirmedItem, offerItem);
        if (!confirmedItem.images) {
          confirmedItem.images = offerItem.images;
        }
        console.log('Item saved');

        // Step 3: Insert a new record in the confirmedOffers table
        await axios.post(`/api/confirmedoffers/${offer._id}`, { offer: offerItem }, {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        });
        console.log('Item added to confirmedoffers');

        // Step 4: Delete the item from the offers table
        await axios.delete(`/api/offers/${offer._id}`, {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        });
        console.log('Item deleted');
        toast.success('Offer is now approved and added your confirmed offers!');
        dispatch({ type: 'DELETE_SUCCESS' });
      } catch (err) {
        toast.error(getError(error));
        dispatch({
          type: 'DELETE_FAIL',
        });
      }
    }
  };

  return (


    <div>
      <Row>
        <Col>
          <h1>Offers made to you</h1>
        </Col>
        <Col className="col text-end">
          <div></div>
        </Col>
      </Row>

      {loadingCreate && <LoadingBox></LoadingBox>}
      {loadingDelete && <LoadingBox></LoadingBox>}

      {loading ? (
        <LoadingBox></LoadingBox>
      ) : error ? (
        <MessageBox variant="danger">{error}</MessageBox>
      ) : (
        <>
          {offers.length === 0 ? (
            <div>No offers yet</div>
          ) : (
            <div style={{ width: '100%', overflowX: 'auto' }}>
              <table className="table-responsive p-3">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>NAME</th>
                    <th>PRICE</th>
                    <th>CATEGORY</th>
                    <th>BRAND</th>
                    <th>ACTIONS</th>
                  </tr>
                </thead>
                <tbody>
                  {offers.map((offer, index) => (
                    <tr
                      key={offer._id}
                      style={{
                        backgroundColor: index % 2 === 0 ? '#f2f2f2' : '#ffffff',
                      }}
                    >
                      <td>
                        <span
                          onClick={() => navigate(`/offer/${offer._id}`)}
                          style={{ cursor: 'pointer' }}
                        >
                          {`...${offer._id.substring(offer._id.length - 4)}`}
                        </span>
                      </td>
                      <td>{offer.name}</td>
                      <td>{offer.price}</td>
                      <td>{offer.category}</td>
                      <td>{offer.brand}</td>
                      <td>
                        <Button
                          type="button"
                          variant="outline-primary"
                          className="me-2"
                          onClick={() => navigate(`/offer/${offer._id}`)}
                        >
                          View
                        </Button>
                        <Button
                          type="button"
                          variant="outline-success"
                          className="me-2"
                          onClick={() => moveItemHandler(offer)}
                        >
                          Approve
                        </Button>
                        <Button
                          type="button"
                          variant="outline-danger"
                          onClick={() => deleteHandler(offer)}
                        >
                          Delete
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div>
                {[...Array(pages).keys()].map((x) => (
                  <Link
                    className={`btn me-2 ${
                      x + 1 === Number(page)
                        ? 'btn-primary'
                        : 'btn-outline-primary'
                    }`}
                    key={x + 1}
                    to={`/admin/offers?page=${x + 1}`}
                  >
                    {x + 1}
                  </Link>
                ))}
              </div>

            </div>
          )}
        </>
      )}

<div style={{ width: '100%', overflowX: 'auto' }}>

<Row>
  <Col>
    <h1>Approved Offers</h1>
  </Col>
</Row>
{loading ? (
  <LoadingBox></LoadingBox>
) : error ? (
  <MessageBox variant="danger">{error}</MessageBox>
) : (
  <table className="table-responsive p-3">
    <thead>
      <tr>
        <th>ID</th>
        <th>Product Name</th>
        <th>Price</th>
        <th>Seller Name</th>
        <th>Actions</th>
      </tr>
    </thead>
    <tbody>
      {confirmedOffers.map((offer) => (
        <tr key={offer._id}>
          <td>{offer._id}</td>
          <td>{offer.name}</td>
          <td>{offer.buyPrice}</td>
          <td>{offer.customerName}</td>
          <td>
            <button
              type="button"
              className="btn btn-sm btn-danger"
              onClick={() => deleteHandler(offer)}
            >
              Delete
            </button>
          </td>
        </tr>
      ))}
    </tbody>
  </table>


)}

</div>

    </div>


  );
}
