import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

function OfferConfirmation() {
  const [address, setAddress] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch('/api/address/latest');
      const data = await response.json();
      setAddress(data);
    };
    fetchData();
  }, []);

  return (
    <div className="confirmation-container">
      <h1 className="confirmation-heading">
        Thank you for submitting your offer!
      </h1>
      <p className="confirmation-text">
        If it is accepted, you will receive funds into the account you provided
        within 5-7 business days.
      </p>
      <p className="confirmation-text">
        Please remember to leave your return address on the parcel as if it is
        not accepted, we will send it back to you at no extra cost!
      </p>
      <p className="confirmation-text">Please send your item to:</p>
      <p>{address.firstLine}</p>
      <p>{address.secondLine}</p>
      <p>{address.city}</p>
      <p>{address.postCode}</p>
      <p className="confirmation-text">
        If you have any questions, please contact us at{' '}
        <a href="mailto:Allan@mobileandconsoletrader.co.uk">
          Allan@mobileandconsoletrader.co.uk
        </a>
        .
      </p>
      <Link to="/" className="home-link">
        Click here to go back home
      </Link>
    </div>
  );
}

export default OfferConfirmation;
