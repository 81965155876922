import React, { useEffect, useReducer, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';
import { getError } from '../utils';
import Container from 'react-bootstrap/Container';
import ListGroup from 'react-bootstrap/ListGroup';
import Form from 'react-bootstrap/Form';
import { Helmet } from 'react-helmet-async';
import LoadingBox from '../components/LoadingBox';
import MessageBox from '../components/MessageBox';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';
import './ImageModal.css';
import 'react-image-gallery/styles/css/image-gallery.css';

const reducer = (state, action) => {
  switch (action.type) {
    case 'FETCH_REQUEST':
      return { ...state, loading: true };
    case 'FETCH_SUCCESS':
      return { ...state, loading: false };
    case 'FETCH_FAIL':
      return { ...state, loading: false, error: action.payload };
    case 'UPDATE_REQUEST':
      return { ...state, loadingUpdate: true };
    case 'UPDATE_SUCCESS':
      return { ...state, loadingUpdate: false };
    case 'UPDATE_FAIL':
      return { ...state, loadingUpdate: false };
    case 'UPLOAD_REQUEST':
      return { ...state, loadingUpload: true, errorUpload: '' };
    case 'UPLOAD_SUCCESS':
      return {
        ...state,
        loadingUpload: false,
        errorUpload: '',
      };
    case 'UPLOAD_FAIL':
      return { ...state, loadingUpload: false, errorUpload: action.payload };

    default:
      return state;
  }
};
export default function OfferEditScreen() {
  const navigate = useNavigate();
  const params = useParams(); // /product/:id
  const { id: offerId } = params;

  const [{ loading, error, loadingUpdate, loadingUpload }, dispatch] =
    useReducer(reducer, {
      loading: true,
      error: '',
    });

  const [name, setName] = useState('');
  const [slug, setSlug] = useState('');
  const [buyPrice, setbuyPrice] = useState('');
  const [image, setImage] = useState('');
  const [images, setImages] = useState([]);
  const [category, setCategory] = useState('');
  const [countInStock, setCountInStock] = useState('');
  const [brand, setBrand] = useState('');
  const [description, setDescription] = useState('');
  const [condition, setCondition] = useState('');
  const [customerName, setCustomerName] = useState('');
  const [sortCode, setSortCode] = useState('');
  const [accountNumber, setAccountNumber] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const handleImageClick = (imageSrc) => {
    setSelectedImage(imageSrc);
    setShowModal(true);
  };

  useEffect(() => {
    console.log('LOADING DATA');
    const fetchData = async () => {
      try {
        dispatch({ type: 'FETCH_REQUEST' });
        const { data } = await axios.get(`/api/offers/${offerId}`);
        setName(data.name);
        setSlug(data.slug);
        setbuyPrice(data.buyPrice);
        setImage(data.image);
        setImages(data.images);
        setCategory(data.category);
        setCountInStock(data.countInStock);
        setBrand(data.brand);
        setDescription(data.description);
        setCondition(data.condition || '');
        setCustomerName(data.customerName || '');
        setSortCode(data.sortCode || '');
        setAccountNumber(data.accountNumber || '');
        dispatch({ type: 'FETCH_SUCCESS' });
      } catch (err) {
        dispatch({
          type: 'FETCH_FAIL',
          payload: getError(err),
        });
      }
    };
    fetchData();
  }, [dispatch, navigate, offerId]);

  const submitHandler = async (e) => {
    e.preventDefault();
    if (images.length < 4) {
      toast.error('You must upload a minimum of 5 images to continue', {
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    }

    try {
      dispatch({ type: 'UPDATE_REQUEST' });
      await axios.put(`/api/offers/${offerId}`, {
        _id: offerId,
        name,
        slug,
        buyPrice,
        image,
        images,
        category,
        brand,
        countInStock,
        description,
        condition,
        customerName,
        sortCode,
        accountNumber,
      });
      dispatch({
        type: 'UPDATE_SUCCESS',
      });
      toast.success('Offer submitted successfully', {
        position: toast.POSITION.TOP_CENTER,
      });
      navigate('/offerconfirmation');
    } catch (err) {
      toast.error(getError(err), {
        position: toast.POSITION.TOP_CENTER,
      });
      dispatch({ type: 'UPDATE_FAIL' });
    }
  };
  const uploadFileHandler = async (e, forImages) => {
    const file = e.target.files[0];
    const bodyFormData = new FormData();
    bodyFormData.append('file', file);
    try {
      dispatch({ type: 'UPLOAD_REQUEST' });
      const { data } = await axios.post('/api/upload', bodyFormData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      dispatch({ type: 'UPLOAD_SUCCESS' });

      if (forImages) {
        setImages([...images, data.secure_url]);
      } else {
        setImage(data.secure_url);
      }
      toast.success('Image uploaded successfully. click Update to apply it', {
        position: toast.POSITION.TOP_CENTER,
      });
    } catch (err) {
      toast.error(getError(err), {
        position: toast.POSITION.TOP_CENTER,
      });
      dispatch({ type: 'UPLOAD_FAIL', payload: getError(err) });
    }
  };
  const deleteFileHandler = async (fileName, f) => {
    console.log(fileName, f);
    console.log(images);
    console.log(images.filter((x) => x !== fileName));
    setImages(images.filter((x) => x !== fileName));
    toast.success('Image removed successfully. click Update to apply it', {
      position: toast.POSITION.TOP_CENTER,
    });
  };
  return (
    <Container className="small-container">
      <Helmet>
        <title>Sell Item</title>
      </Helmet>
      <h1>Sell Item</h1>

      {loading ? (
        <LoadingBox></LoadingBox>
      ) : error ? (
        <MessageBox variant="danger">{error}</MessageBox>
      ) : (
        <Form onSubmit={submitHandler}>
          <Form.Group controlId="name">
            <Form.Label>Name</Form.Label>
            <Form.Control type="text" value={name} disabled />
          </Form.Group>

          <Form.Group className="mb-3" controlId="condition">
            <Form.Label>Condition</Form.Label>
            <Form.Control
              as="select"
              value={condition}
              onChange={(e) => setCondition(e.target.value)}
            >
              <option value="New">New</option>
              <option value="Like New">Like New</option>
              <option value="Used">Used</option>
            </Form.Control>
          </Form.Group>

          <Form.Group controlId="buyPrice">
            <Form.Label>Buy Price</Form.Label>
            <Form.Control type="number" value={buyPrice} disabled />
          </Form.Group>
          <Form.Group className="mb-3" controlId="imageFile">
            <Form.Label>Choose your main image</Form.Label>
            <Form.Control type="file" onChange={uploadFileHandler} />
            {loadingUpload && <LoadingBox></LoadingBox>}
          </Form.Group>

          <Form.Group className="mb-3" controlId="additionalImage">
            <Form.Label>Choose 4 additional images</Form.Label>
            {images.length === 0 && <MessageBox>No image</MessageBox>}
            <ListGroup variant="flush">
              {images.map((x) => (
                <ListGroup.Item key={x}>
                  <div className="my-3 thumbnail">
                    <Image
                      src={x}
                      alt={name}
                      className="clickable"
                      onClick={() => handleImageClick(x)}
                      width="100"
                      height="100"
                    />
                  </div>
                  <Button variant="light" onClick={() => deleteFileHandler(x)}>
                    <i className="fa fa-times-circle"></i>
                  </Button>
                </ListGroup.Item>
              ))}
            </ListGroup>
          </Form.Group>
          <Form.Group className="mb-3" controlId="additionalImageFile">
            <Form.Label>Upload Aditional Image</Form.Label>
            <Form.Control
              type="file"
              onChange={(e) => uploadFileHandler(e, true)}
            />
            {loadingUpload && <LoadingBox></LoadingBox>}
          </Form.Group>
          {showModal && (
            <div className="modal" onClick={() => setShowModal(false)}>
              <div className="modal-content">
                <img src={selectedImage} alt={name} />
              </div>
            </div>
          )}

          <Form.Group controlId="category">
            <Form.Label>Category</Form.Label>
            <Form.Control
              type="text"
              value={category}
              onChange={(e) => setCategory(e.target.value)}
              disabled
            />
          </Form.Group>

          <Form.Group controlId="brand">
            <Form.Label>Brand</Form.Label>
            <Form.Control type="text" value={brand} disabled />
          </Form.Group>
          <Form.Group className="mb-3" controlId="countInStock">
            <Form.Label>How many you'd like to sell</Form.Label>
            <Form.Control
              value={countInStock}
              onChange={(e) => setCountInStock(e.target.value)}
              required
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="description">
            <Form.Label>Describe the condition of your item</Form.Label>
            <Form.Control
              as="textarea"
              rows={5}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              required
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="customerName">
            <Form.Label>
              Please enter the name of the account you'd like to be paid into
            </Form.Label>
            <Form.Control
              value={customerName}
              onChange={(e) => setCustomerName(e.target.value)}
              required
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="sortCode">
            <Form.Label>Sort Code</Form.Label>
            <Form.Control
              value={sortCode}
              onChange={(e) => {
                const input = e.target.value.replace(/\D/g, '').substring(0, 6);
                const parts = input.match(/^(\d{2})(\d{2})(\d{2})$/);
                if (parts) {
                  e.target.value = `${parts[1]}-${parts[2]}-${parts[3]}`;
                  setSortCode(e.target.value.replace(/\D/g, ''));
                } else {
                  setSortCode(input);
                }
              }}
              required
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="accountNumber">
            <Form.Label>Account Number</Form.Label>
            <Form.Control
              value={accountNumber}
              onChange={(e) =>
                setAccountNumber(
                  e.target.value.replace(/\D/g, '').substring(0, 8)
                )
              }
              minLength={8}
              maxLength={8}
              required
            />
          </Form.Group>
          <div className="mb-3">
            <Button disabled={loadingUpdate} type="submit">
              Submit Offer
            </Button>
            {loadingUpdate && <LoadingBox></LoadingBox>}
          </div>
        </Form>
      )}
    </Container>
  );
}
